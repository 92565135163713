<template>
  <div v-if="isDefault" class="checkout-product-items">
    <div
      v-for="item in data"
      :key="item.id"
      class="checkout-product-item"
    >
      <div class="checkout-product-item-image">
        <img
          :src="item.imageUrl || item.image_url"
          alt="product image"
          class="image"
        />

        <div class="badge-container">
          <div class="badge">1</div>
        </div>
      </div>
      <div class="product-name">
        {{ item.name }}
      </div>
      <div class="price text-right">
        <div>
          <template v-if="+item.price_ship === 0">
            ฟรี
          </template>
          <template v-else>
            {{ item.price_ship | currency2 }}
          </template>
        </div>

        <!-- <div v-if="index != 0">
          <span class="btn-remove" @click="$emit('remove', item)">
            Remove
          </span>
        </div> -->
      </div>
    </div>
  </div>
  <div v-else class="checkout-product-items not-default">
    <div
      v-if="mainProduct"
      class="checkout-product-item main-product"
    >
      <div class="checkout-product-item-image">
        <img
          :src="
            resizeImage(
              mainProduct.imageUrl || mainProduct.image_url,
              640,
            )
          "
          alt="product image"
          class="image"
        />
      </div>
      <div class="w-full"></div>
      <div class="product-name">
        {{ mainProduct.name }}
      </div>
      <div class="price text-right">
        <div>
          {{ mainProduct.price_ship | currency2 }}
        </div>
      </div>
    </div>

    <PDPAccordionItem
      v-if="additionalItems.length > 0"
      isShowLabelExpand
      :labelShow="`แสดงอีก ${additionalItems.length} รายการ`"
      :labelHide="`ซ่อน ${additionalItems.length} รายการ`"
    >
      <template v-slot:content>
        <div class="checkout-product-items">
          <div
            v-for="item in additionalItems"
            :key="item.id"
            class="checkout-product-item"
          >
            <div class="checkout-product-item-image">
              <img
                :src="resizeImage(item.imageUrl || item.image_url)"
                alt="product image"
                class="image"
              />

              <!-- <div class="badge-container">
                <div class="badge">1</div>
              </div> -->
            </div>
            <div class="product-name">
              {{ item.name }}
            </div>
            <div class="price text-right">
              <div>
                <template v-if="+item.price_ship === 0">
                  ฟรี
                </template>
                <template v-else>
                  {{ item.price_ship | currency2 }}
                </template>
              </div>

              <!-- <div v-if="index != 0">
                        <span class="btn-remove" @click="$emit('remove', item)">
                          Remove
                        </span>
                      </div> -->
            </div>
          </div>
        </div>
      </template>
    </PDPAccordionItem>
  </div>
</template>

<script>
import { resizeImage } from '@/utils/helpers'

export default {
  name: 'CheckoutProductItems',
  components: {
    PDPAccordionItem: () =>
      import('@/components/studio7/PDPAccordionItem.vue'),
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDefault: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mainProduct() {
      return this.data[0]
    },
    additionalItems() {
      return this.data.slice(1)
    },
  },
  methods: {
    resizeImage(imageUrl, size = 300) {
      return resizeImage(imageUrl, size)
    },
  },
}
</script>

<style lang="stylus" scoped>
.checkout-product-items {
    display: flex;
    flex-direction: column;
    gap: 14px

    .checkout-product-item {
        display flex
        align-items center
        flex-wrap wrap
        gap 0 14px

        &.main-product {
            .checkout-product-item-image {
                position relative
                width 100%
                height auto
                max-height 390px
                min-height 390px
                flex-basis 100%
                border-radius 5px
                border none
                margin 0 auto
                aspect-ratio 1 / 1

                .image {
                    width 100%
                    height 100%

                    object-fit contain
                    object-position center
                    border-radius 5px
                }
            }

            .product-name {
                font-size 20px
                font-weight 600
            }
        }
    }



    .checkout-product-item-image {
        position relative
        width 64px
        height 64px
        border 1px solid rgb(214, 214, 214)
        border-radius 5px

        .image {
            width 100%
            height 100%

            object-fit contain
            object-position center
            border-radius 5px
        }
    }


    .product-name {
        position relative
        box-sizing border-box
        display block
        flex: 1
        min-height 21px
        margin-bottom 0px
        margin-left 0px
        margin-right 0px
        margin-top 0px
        width 284.195px
        text-size-adjust 100%
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        text-transform none
        word-break break-word
        color rgb(0, 0, 0)
        margin-block-end 0px
        margin-block-start 0px
        margin-inline-end 0px
        margin-inline-start 0px
        overflow-wrap anywhere
        unicode-bidi isolate
    }

    .price {
        box-sizing border-box
        text-size-adjust 100%
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        text-transform none
        text-wrap nowrap
        white-space-collapse preserve
    }

    .badge-container {
        position absolute
        top 0px
        right 0px
        transform: translate(25%, -50%);
    }

    .badge {
        align-items center
        box-sizing border-box
        display inline-flex
        height 22px
        justify-content center
        min-block-size 22px
        min-height 22px
        min-inline-size 22px
        min-width 22px
        padding-left 7px
        padding-right 7px
        width 22px
        text-size-adjust 100%
        font-size 12px
        font-weight 700
        letter-spacing normal
        line-height 18px
        text-transform none
        color rgb(245, 245, 245)
        background-color rgb(102, 102, 102)
        -webkit-font-smoothing subpixel-antialiased
        border-bottom-left-radius 36px
        border-bottom-right-radius 36px
        border-top-left-radius 36px
        border-top-right-radius 36px
        overflow-wrap break-word
        padding-inline-end 7px
        padding-inline-start 7px
        unicode-bidi isolate
    }

    .btn-remove {
        cursor pointer
        color rgb(0, 113, 227)
    }
}
</style>
